.ant-select-tree-node-content-wrapper {
  white-space: nowrap;
  /* Evita que o texto quebre para a próxima linha */
  overflow: hidden;
  /* Esconde o conteúdo que ultrapassa a largura do elemento */
  text-overflow: ellipsis;
  /* Adiciona os três pontinhos no final do texto cortado */
}
.ant-select-tree-title,
.ant-select-tree-switcher-icon {
  line-height: 40px !important;
}
.ant-select-tree-switcher-noop {
  display: none !important;
}
.badge-in sup {
  margin-right: 20px !important;
  margin-top: 20px !important;
}
.ant-input-prefix {
  margin-right: 8px !important;
}
.ant-input-suffix {
  margin-left: 8px !important;
}
.ant-form-item-explain {
  font-size: 12px !important;
}
.ant-form-item-feedback-icon,
.ant-input-clear-icon {
  margin-bottom: -2px !important;
}
.ant-form-item-explain-error {
  padding: 2px 0 2px 8px !important;
  overflow: hidden !important;
  position: absolute !important;
}
.ant-form-item-label label {
  width: 100% !important;
}
.nav-profile-header.user-details {
  max-width: 200px !important;
}
svg.menu-with-react-icons {
  width: 16px !important;
  height: 16px !important;
  margin-left: -1px !important;
}
button.force-mt-2 span.ant-btn-icon svg {
  margin-top: 2px !important;
}
button.force-mt-3 span.ant-btn-icon svg {
  margin-top: 3px !important;
}
div.nowrap-line {
  width: unset !important;
}
.fix-segmented-react-icons .ant-segmented-item-icon svg {
  margin-bottom: -3px;
  font-size: 16px;
}
.table-with-border {
  border: 1px solid #8080801c !important;
  border-radius: 8px !important;
}
div.ant-table-filter-dropdown-btns {
  display: none !important;
}
body.fix-scrollbar {
  /* Estilizando a barra de rolagem */
  /* Estilizando a "trilha" (o fundo da barra) */
}
body.fix-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  /* Largura da barra de rolagem */
}
body.fix-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  /* Cor do pulgar */
}
/*

.BLUE {
  &::-webkit-scrollbar-thumb {
    background-color: #3e79f7 !important;
  }
}


.PURPLE {
  &::-webkit-scrollbar-thumb {
    background-color: #a461d8 !important;
  }
}


.CYAN {
  &::-webkit-scrollbar-thumb {
    background-color: #04d182 !important;
  }
}

.GREEN {
  &::-webkit-scrollbar-thumb {
    background-color: #21B573 !important;
  }
}

.MAGENTA {
  &::-webkit-scrollbar-thumb {
    background-color: #eb2f96 !important;
  }
}

.PINK {
  &::-webkit-scrollbar-thumb {
    background-color: #eb2f96 !important;
  }
}

.RED {
  &::-webkit-scrollbar-thumb {
    background-color: #de4436 !important;
  }
}

.ORANGE {
  &::-webkit-scrollbar-thumb {
    background-color: #fa8c16 !important;
  }
}

.YELLOW {
  &::-webkit-scrollbar-thumb {
    background-color: #fadb14 !important;
  }
}

.VOLCANO {
  &::-webkit-scrollbar-thumb {
    background-color: #ff6b72 !important;
  }
}

.GEEK_BLUE {
  &::-webkit-scrollbar-thumb {
    background-color: #17bcff !important;
  }
}

.LIME {
  &::-webkit-scrollbar-thumb {
    background-color: #a0d911 !important;
  }
}

.GOLD {
  &::-webkit-scrollbar-thumb {
    background-color: #ffc542 !important;
  }
}
*/
/*
.CUSTOM_MULTI_APP {
  &::-webkit-scrollbar-thumb {
    background-color: #168BB1 !important;
  }
}

.CUSTOM_VEERTICAL_APP {
  &::-webkit-scrollbar-thumb {
    background-color: #355C7D !important;
  }
}
*/
.disabled-button {
  background-color: #ccc;
  /* Cor de fundo cinza */
  color: #999;
  /* Cor de texto cinza mais clara */
  cursor: not-allowed;
  /* Cursor não permitido */
  opacity: 0.6;
  /* Opacidade reduzida para indicar desativação */
  pointer-events: none;
  /* Desabilitar eventos do mouse */
}
.tab-in-form .ant-tabs-nav-wrap {
  padding-left: 8px !important;
}
.skeleton-without-paragraph .ant-skeleton-paragraph {
  margin: 0 !important;
}
.ant-btn-icon {
  margin-top: 1px !important;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px !important;
}
.title-without-margin {
  align-items: center;
}
.title-without-margin .ant-card-meta-title {
  margin-bottom: 0 !important;
}
.title-without-margin.brake-title .ant-card-head-title {
  white-space: unset !important;
}
.title-without-margin .card-brake-title .ant-card-head-title,
.title-without-margin .ant-card-meta-title {
  white-space: unset !important;
}
.price-from-to .ant-statistic-title {
  text-decoration: line-through !important;
}
.price-from-to .ant-statistic-content-prefix {
  color: rgba(69, 85, 96, 0.45) !important;
  font-size: 14px !important;
}
.price-from-to-value .ant-statistic-content-prefix,
.price-from-to-value .ant-statistic-content-value {
  text-decoration: line-through !important;
}
.fit-last-item div.ant-steps-item.ant-steps-item-finish.ant-steps-item-custom:last-child .ant-steps-item-description {
  padding-bottom: 0 !important;
}
button.fixed-with-90 {
  min-width: 90px !important;
}
.title-fixed-with-18 .ant-modal-body {
  margin-top: 18px !important;
}
.form-item-no-margin.ant-form-item {
  margin: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.modal-content-padding-bottom-6 > .ant-modal-content {
  padding-bottom: 6px !important;
}
div.hide-last-line-table tr.ant-table-row:last-child td.ant-table-cell {
  border-bottom: unset !important;
}
div.hide-last-line-table tr.ant-table-placeholder td.ant-table-cell {
  border-bottom: unset !important;
}
.strong-border {
  border-color: #c0c0c0 !important;
}
.cad-with-body-no-padding .ant-card-body {
  padding: 0 !important;
}
.cad-with-body-no-padding div.ant-table-wrapper {
  border-radius: 0 0 10px 10px !important;
}
.no-shadow {
  box-shadow: none !important;
}
.page-header-alt {
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15) !important;
}
.ant-modal-title svg {
  margin-bottom: -2px !important;
}
iframe {
  display: none !important;
}
.no-table-overflow.ant-table-wrapper {
  overflow-x: unset !important;
}
.no-pointer-cursor {
  cursor: default !important;
}
.no-pointer-cursor:hover {
  cursor: default !important;
}
.no-icon-space span.anticon:empty {
  display: none !important;
}
.no-icon-space span.anticon:empty + span {
  margin-left: 0 !important;
}
.modal-top .ant-modal-body {
  padding-top: 20px !important;
}
div.ant-upload-list-item .ant-upload-list-item-name {
  margin-top: 6px !important;
}
div.ant-upload-list-item button.ant-btn.ant-upload-list-item-action {
  height: 48px !important;
}
.force-disabled div.ant-upload-list-item .ant-upload-list-item-actions,
.force-disabled div.ant-upload-list-item button,
.force-disabled div.ant-upload-list-item .ant-btn-icon {
  pointer-events: none;
  cursor: not-allowed;
  border-color: #e6ebf1;
  color: rgba(69, 85, 96, 0.25);
  background-color: unset;
  box-shadow: none;
}
svg.icon-adapted.icon-mt-4 {
  margin-top: 4px;
  margin-bottom: -4px;
}
.center-modal-buttons .ant-modal-footer {
  text-align: center !important;
}
/* set background color for message toast */
.ant-message-notice-content,
.message-info .ant-message-notice-content {
  background-color: #424242 !important;
  color: white !important;
}
.ant-form-item-label label {
  white-space: nowrap;
}
.no-collapse-padding {
  margin-top: 18px !important;
}
.no-collapse-padding .ant-collapse-item {
  width: 100% !important;
}
.no-collapse-padding .ant-collapse-header-text {
  font-weight: bold !important;
}
.no-collapse-padding .ant-collapse-header {
  padding: 0 !important;
}
.no-collapse-padding .ant-collapse-content-box {
  padding: 16px 0 0 !important;
}
.addon-with-conversion .ant-input-number-group-addon:first-child {
  min-width: 68px !important;
}
.addon-with-conversion .ant-input-number-group-addon:last-child {
  min-width: 108px !important;
  text-align: right !important;
}
.padding-1 {
  margin-right: 4px !important;
}
.padding-1 svg {
  padding: 1px !important;
}
.ant-color-picker-trigger {
  height: unset !important;
  padding: 8px !important;
}
div.no-max-width-for-description div.ant-steps-item-description {
  max-width: unset !important;
}
.ant-select-selector-p8 .ant-select-selector {
  padding: 8px !important;
}
.ant-select-selector-p8 span.ant-select-arrow {
  margin-top: 2px !important;
}
.with-select-in-body span.ant-select-arrow {
  margin-top: 2px !important;
}
.ant-card-hoverable.selected-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid darkcyan;
}
.text-primary {
  color: red !important;
}
.hide-up-down-buttons .ant-input-number-handler-wrap {
  display: none;
  /* Ocultar os botões de incremento/decremento */
}
body.body-dark-theme {
  background: #283142 !important;
}
aside.ant-layout-sider {
  z-index: 998 !important;
}
aside.ant-layout-sider.css-1iah3kt-SideNav {
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15) !important;
}
aside.ant-layout-sider.css-bmbel4-SideNav {
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.3) !important;
}
.agenda-settings-container {
  margin-bottom: 48px !important;
}
.agenda-settings-card {
  width: 368px !important;
  margin: 16px 8px 8px !important;
}
.card-clickable-effect:hover {
  background: #ededed !important;
  cursor: pointer !important;
}
.agenda-settings-card.add-agenda {
  width: 368px !important;
  height: 128px !important;
  font-size: 36px !important;
  color: grey !important;
  opacity: 0.4 !important;
}
.agenda-settings-card .tag {
  margin: 16px 4px 0 !important;
}
.calendar-scheduling .ant-picker-calendar-mode-switch {
  content-visibility: hidden !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  border: 1px gray solid !important;
  background-color: unset !important;
  color: gray !important;
}
li.ant-picker-ok,
li.ant-picker-ok button {
  width: 100% !important;
}
.form-item-hour input {
  border-radius: unset !important;
}
.checkbox-like-radio.ant-checkbox-group {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #455560 !important;
  font-variant: tabular-nums !important;
  line-height: 1.5 !important;
  list-style: none !important;
  font-feature-settings: "tnum" !important;
  display: inline-block !important;
  font-size: 0 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper {
  position: relative !important;
  display: inline-block !important;
  height: 40px !important;
  margin: 0 0 0 -1px !important;
  padding: 0 15px !important;
  color: #455560 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  background: #ffffff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e6ebf1 !important;
  border-image: initial !important;
  cursor: pointer !important;
  transition: color 0.3s ease 0s, background 0.3s ease 0s, border-color 0.3s ease 0s, box-shadow 0.3s ease 0s !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-inner {
  visibility: hidden;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
  z-index: 1 !important;
  color: #3e79f7 !important;
  background: #ffffff !important;
  border: 1px solid #3e79f7 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
  border-color: #a2a2a3 !important;
  z-index: 1;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled:first-child {
  border-left-color: #a2a2a3 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: -1 !important;
  width: 100% !important;
  height: 100% !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-disabled) span {
  color: #699dff !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-inner {
  display: table-row !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:first-child {
  margin: 0 !important;
  border-left: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem 0 0 0.625rem !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:first-child.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
  border-left: 1px solid #3e79f7 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:last-child {
  border-radius: 0 0.625rem 0.625rem 0 !important;
}
.tag-col-container .ant-tag {
  margin-top: 8px !important;
}
.tag-col-container:last-child .ant-tag {
  margin-right: 0 !important;
}
.progress .ant-progress-text {
  text-align: right !important;
}
.like-a-card {
  margin-bottom: 24px !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
}
.photo-module-form-header {
  background-color: #FFFFFF !important;
  border-radius: 10px !important;
  margin-top: -48px !important;
  min-height: 158px !important;
  min-width: 158px !important;
  max-height: 158px !important;
  max-width: 158px !important;
}
span.ant-upload-wrapper.photo-module-form-header.css-dev-only-do-not-override-ojzbjh.ant-upload-picture-card-wrapper {
  margin: 0 !important;
}
span.ant-upload-wrapper.photo-module-form-header.css-dev-only-do-not-override-ojzbjh.ant-upload-picture-card-wrapper .ant-upload-list-picture-card .ant-upload,
span.ant-upload-wrapper.photo-module-form-header.css-dev-only-do-not-override-ojzbjh.ant-upload-picture-card-wrapper .ant-upload-list-picture-card .ant-upload-list-item-container {
  width: 100% !important;
  height: 156px !important;
}
.toolbar-wrapper {
  position: fixed !important;
  bottom: 32px !important;
  left: 50% !important;
  padding: 0px 24px !important;
  color: #fff !important;
  font-size: 20px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 100px !important;
  transform: translateX(-50%) !important;
}
.toolbar-wrapper .anticon {
  padding: 12px !important;
  cursor: pointer !important;
}
.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed !important;
  opacity: 0.3 !important;
}
.toolbar-wrapper .anticon:hover {
  opacity: 0.3 !important;
}
img.ant-image-preview-img {
  width: 300px !important;
}
.ant-space-item:first-child {
  width: 100% !important;
}
.parameters-list-form.anticon-minus-circle {
  margin-bottom: 26px !important;
}
@media (max-width: 768px) {
  .ant-picker-dropdown {
    display: none;
  }
  .mobile-container {
    display: flex !important;
    justify-content: flex-end !important;
    /* Alinha o conteúdo à direita */
  }
}
.btn-add-passenger {
  background: #ffffff !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.88) !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02) !important;
}
.btn-add-passenger:hover {
  color: #189696 !important;
  border-color: #189696 !important;
  background: #ffffff !important;
}
span.tag-no-margin {
  margin-inline-end: 0 !important;
}
.green {
  color: green;
}
.red {
  color: red;
}
.gray {
  color: gray;
}
.custom-statistic .ant-statistic-title {
  text-decoration: none !important;
}
.ant-menu-title-content {
  padding-left: 1px !important;
}
.avatar-select.board-card-modal .ant-select-selector {
  padding: 0 11px !important;
  border: 1px solid #e6ebf1 !important;
}
.avatar-select.board-card-modal.select {
  margin-left: 0 !important;
}
.color-picker-small.ant-color-picker-trigger {
  min-width: 14px !important;
  min-height: 14px !important;
  padding: 3px !important;
  max-width: 24px !important;
  max-height: 24px !important;
}
.color-picker-small.ant-color-picker-trigger .ant-color-picker-color-block {
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px !important;
}
.water-tank .ant-card-body {
  padding: 12px !important;
}
.disable-focus-canvas canvas {
  cursor: default !important;
}
.list-page-icon {
  margin-top: -4px !important;
}
.distance-sensor circle {
  fill: #dedede;
}
.distance-sensor polygon {
  fill: #dedede;
}
.distance-sensor path {
  fill: #d6d6d6;
}
.ant-collapse.collapse-top .ant-collapse-expand-icon {
  margin-top: 6px !important;
}
.dropdown-content {
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px 32px;
  position: relative;
}
.dropdown-content::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active) {
  background-color: unset !important;
}
